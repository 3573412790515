import React from 'react';
import { navigate } from 'gatsby';
import { globalHistory } from '@reach/router';
import { useFormik } from 'formik';
import copy from 'clipboard-copy';
import Button from 'components/Core/Button';
import { STIcon } from 'components/Brand/Icons/STIcon';
import TextField from 'components/Core/TextField';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import HyperLink from 'components/Core/HyperLink';
import useBusinessCalcResultImages from 'hooks/useBusinessCalcResultImages';
import { convertToolResultToParams } from 'utils/urlHelper';
import { getFormValid } from 'utils/formUtils';
import { ResultsHeroContainer } from '../styles';
import { ProfitList, BillableHourList } from '../utils/content';
import SocialSharing from 'components/SocialSharing';

export const LRCResultsHero = ({ results, ...otherProps }) => {
  const checkValidate = (value) => value !== '' && value >= 0;

  const formik = useFormik({
    initialValues: {
      overhead: results.overhead || '',
      techCount: results.techCount || '',
      holidaysPerTech: results.holidaysPerTech || '',
      vacationDaysPerTech: results.vacationDaysPerTech || '',
      billableHourEfficiency: results.billableHourEfficiency || '',
      hourlyRatePerTech: results.hourlyRatePerTech || '',
      netProfit: results.netProfit || '',
    },
    validate: (values) => {
      return {
        ...(!checkValidate(values.overhead) && {
          overhead: 'Overhead is invalid',
        }),
        ...(!checkValidate(values.techCount) && {
          techCount: 'Tech Count is invalid',
        }),
        ...(!checkValidate(values.holidaysPerTech) && {
          holidaysPerTech: 'Holidays per Tech is invalid',
        }),
        ...(!checkValidate(values.vacationDaysPerTech) && {
          vacationDaysPerTech: 'Vacation day per Tech is invalid',
        }),
        ...(!checkValidate(values.billableHourEfficiency) && {
          billableHourEfficiency: 'Billable hour efficiency is invalid',
        }),
        ...(!checkValidate(values.hourlyRatePerTech) && {
          hourlyRatePerTech: 'Hourly rate per Tech is invalid',
        }),
        ...(!checkValidate(values.netProfit) && {
          netProfit: 'Net Profit is invalid',
        }),
      };
    },
    onSubmit: (values) => {
      doRecalc(values);
    },
  });

  const pageImages = useBusinessCalcResultImages();
  const backImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'bg-main-01-calc')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  function numberWithDollar(x) {
    return x && `$ ${parseFloat(x.toString()).toFixed(2)}`;
  }

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(
      id,
      id !== 'billableHourEfficiency' || id !== 'netProfit'
        ? parseFloat(newValue.replace(/\$|,/g, ''))
        : newValue,
    );
  };

  const convertResultParams = (result) => {
    return convertToolResultToParams([
      result.overhead,
      result.techCount,
      result.holidaysPerTech,
      result.vacationDaysPerTech,
      result.billableHourEfficiency,
      result.hourlyRatePerTech,
      result.netProfit,
      result.projectedBillableHoursPerTech,
      result.coverOverheadOnly,
      result.breakEvenRate,
      result.billableLaborRate,
    ]);
  };

  const doRecalc = () => {
    const tmpValues = { ...formik.values };

    const totalWeeks = 52;
    const workingHoursPerWeek = 40;
    const availableWorkingHours = parseFloat(
      totalWeeks * workingHoursPerWeek -
        (tmpValues.holidaysPerTech + tmpValues.vacationDaysPerTech) * 8,
    ).toFixed(2);
    tmpValues.projectedBillableHoursPerTech = parseFloat(
      (availableWorkingHours * tmpValues.billableHourEfficiency) / 100,
    ).toFixed(2);
    tmpValues.coverOverheadOnly = parseFloat(
      tmpValues.overhead / (tmpValues.techCount * tmpValues.projectedBillableHoursPerTech),
    ).toFixed(2);
    tmpValues.breakEvenRate = parseFloat(
      tmpValues.hourlyRatePerTech + tmpValues.coverOverheadOnly,
    ).toFixed(2);
    tmpValues.billableLaborRate = parseFloat(
      (tmpValues.breakEvenRate * 100) / (100 - tmpValues.netProfit),
    ).toFixed(2);

    navigate(`/tools/labor-rate-calculator-results?${convertResultParams(tmpValues)}`);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <ResultsHeroContainer backgroundImg={backImg} {...otherProps} id="section-lrc-results-hero">
      <div className="section-lrc-results-hero--wrapper">
        <div className="section-lrc-results-hero--container">
          <div className="section-lrc-results-hero--first-section">
            <div className="section-lrc-results-hero--first-left-side">
              <div className="section-lrc-results-hero--results-container">
                <h2 className="section-lrc-results-hero--results-title font-heading-lg">
                  Your Results
                </h2>
                <div className="section-lrc-results-hero--results-row">
                  <h3 className="section-lrc-results-hero--results-label">Break Even Rate</h3>
                  <p className="section-lrc-results-hero--results-value">
                    {numberWithDollar(results.breakEvenRate)}
                  </p>
                </div>
                <div className="section-lrc-results-hero--results-row">
                  <h3 className="section-lrc-results-hero--results-label">Cover Overhead only</h3>
                  <p className="section-lrc-results-hero--results-value">
                    {numberWithDollar(results.coverOverheadOnly)}
                  </p>
                </div>
                <div className="section-lrc-results-hero--results-row">
                  <h3 className="section-lrc-results-hero--results-label">Billable Labor Rate</h3>
                  <p className="section-lrc-results-hero--results-value">
                    {numberWithDollar(results.billableLaborRate)}
                  </p>
                </div>
              </div>
            </div>
            <div className="section-lrc-results-hero--first-right-side">
              <div className="section-lrc-results-hero--demo-container">
                <h2 className="section-lrc-results-hero--demo-title font-h3">
                  Transform your business with all-in-one system.
                </h2>
                <p className="section-lrc-results-hero--demo-description">
                  Explore why 100,000+ contractors trust Service Titan to run their business.
                </p>
                <HyperLink data-position="Body - Get Demo CTA" href="/demo">
                  <Button className="section-lrc-results-hero--get-demo-btn" shadow={false}>
                    Get Demo
                  </Button>
                </HyperLink>
              </div>
            </div>
          </div>

          <div className="section-lrc-results-hero--second-section">
            <h2 className="section-lrc-results-hero--share-title font-heading-lg">
              Share this report:
            </h2>
            <div className="section-lrc-results-hero--share-container">
              <SocialSharing pageUrl={globalHistory.location.href} />
              <div className="section-lrc-results-hero--share-splitter" />
              <Button
                className="section-lrc-results-hero--share-copy-btn"
                onClick={() => copy(globalHistory.location.href)}
                shadow={false}
              >
                Copy URL
              </Button>
            </div>
          </div>

          <div className="section-lrc-results-hero--third-section">
            <div className="section-lrc-results-hero--recalc-header">
              <h2 className="section-lrc-results-hero--recalc-title">Edit data</h2>
              <HyperLink
                className="section-lrc-results-hero--retake-button"
                href="/tools/labor-rate-calculator"
              >
                <STIcon type="icon-retake" />
                Retake
              </HyperLink>
            </div>
            <div className="section-lrc-results-hero--recalc-form">
              <div className="section-lrc-results-hero--recalc-column">
                <div className="section-lrc-results-hero--recalc-item-row">
                  <FormLabelWithField
                    className="lrc-step--field-question"
                    label="Annual Overhead"
                    isRequired={false}
                    helperText="What were your overhead expenses last year?"
                  />
                  <TextField
                    className="lrc-step--field-text"
                    id="overhead"
                    type="currency"
                    placeholderText="Enter Amount"
                    values={formik.values}
                    formValid={formValid}
                    formErrors={formik.errors}
                    handleChange={onHandleChange}
                    decimalScale={0}
                  />
                </div>
                <div className="section-lrc-results-hero--recalc-item-row">
                  <FormLabelWithField
                    className="lrc-step--field-question"
                    label="Techs on payroll"
                    isRequired={false}
                    helperText="How many Techs do you expect to be on your payroll?"
                  />
                  <TextField
                    className="lrc-step--field-text"
                    id="techCount"
                    type="currency"
                    placeholderText="Enter Amount"
                    values={formik.values}
                    formValid={formValid}
                    formErrors={formik.errors}
                    handleChange={onHandleChange}
                    decimalScale={0}
                  />
                </div>
                <div className="section-lrc-results-hero--recalc-item-row">
                  <FormLabelWithField
                    className="lrc-step--field-question"
                    label="Average Holidays per Tech"
                    isRequired={false}
                    helperText="Average Holidays per Tech per year?"
                  />
                  <TextField
                    className="lrc-step--field-text"
                    id="holidaysPerTech"
                    type="number"
                    placeholderText="Amount of days"
                    values={formik.values}
                    formValid={formValid}
                    formErrors={formik.errors}
                    handleChange={onHandleChange}
                    decimalScale={0}
                  />
                </div>
              </div>
              <div className="section-lrc-results-hero--recalc-column">
                <div className="section-lrc-results-hero--recalc-item-row">
                  <FormLabelWithField
                    className="lrc-step--field-question"
                    label="Average Vacations per Tech"
                    isRequired={false}
                    helperText="Average Vacation days per Tech per year?"
                  />
                  <TextField
                    className="lrc-step--field-text"
                    id="vacationDaysPerTech"
                    type="number"
                    placeholderText="Amount of days"
                    values={formik.values}
                    formValid={formValid}
                    formErrors={formik.errors}
                    handleChange={onHandleChange}
                    decimalScale={0}
                  />
                </div>
                <div className="section-lrc-results-hero--recalc-item-row">
                  <FormLabelWithField
                    className="lrc-step--field-question"
                    label="Billable hour efficiency"
                    helperText="What is your average billable hour efficiency?"
                    isRequired={false}
                  />
                  <Select
                    className="lrc-step--field-select"
                    id="billableHourEfficiency"
                    options={BillableHourList}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </div>
                <div className="section-lrc-results-hero--recalc-item-row">
                  <FormLabelWithField
                    className="lrc-step--field-question"
                    label="Average hourly rate per Tech"
                    isRequired={false}
                    helperText="Average hourly rate per Tech?"
                  />
                  <TextField
                    className="lrc-step--field-text"
                    id="hourlyRatePerTech"
                    type="currency"
                    placeholderText="Enter Amount"
                    values={formik.values}
                    formValid={formValid}
                    formErrors={formik.errors}
                    handleChange={onHandleChange}
                    decimalScale={0}
                  />
                </div>
              </div>
              <div className="section-lrc-results-hero--recalc-column">
                <div className="section-lrc-results-hero--recalc-item-row">
                  <FormLabelWithField
                    className="lrc-step--field-question"
                    label="Desired Net Profit"
                    isRequired={false}
                    helperText="What is your desired net profit?"
                  />
                  <Select
                    className="lrc-step--field-select"
                    id="netProfit"
                    values={formik.values}
                    options={ProfitList}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </div>

                <div className="section-lrc-results-hero--recalc-item-row">
                  <Button
                    className="lrc-step--button-recalc"
                    type="secondary"
                    shadow={false}
                    onClick={() => formik.submitForm()}
                  >
                    Recalculate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ResultsHeroContainer>
  );
};

export default LRCResultsHero;
